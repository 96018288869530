.lia-status {
  display: grid;
  padding: 0 5px;
  align-items: center;
  max-width: 240px;
  grid-template-columns: auto 1fr;

  &-label {
    display: inline-flex;
    max-width: 100%;
    width: fit-content;
    font-size: var(--lia-bs-small-font-size);
    font-weight: var(--lia-bs-font-weight-normal);
    color: var(--lia-bs-gray-900);
    align-items: center;
    line-height: 1.09090909;

    &.lia-is-boxed {
      font-size: var(--lia-font-size-xxs);
      font-weight: var(--lia-font-weight-md);
      letter-spacing: 0.5px;
      text-transform: uppercase;
      border-radius: var(--lia-bs-border-radius-sm);
      border: 1px solid transparent;
      padding: 1px 10px;
    }

    &.lia-is-filled {
      background-color: var(--lia-bs-gray-900);
      color: var(--lia-bs-white) !important;
      border-color: var(--lia-bs-gray-900);
    }

    &.lia-is-outline {
      border-color: var(--lia-bs-gray-900);
    }

    &.lia-is-textonly {
      max-height: var(--lia-bs-font-size-base);
      line-height: normal;
    }
  }
}
